import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Dar from "@mui/icons-material/DoubleArrow"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { IconRow } from "../components/resources"
import { v4 as uuidv4 } from "uuid"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/careers/",
    label: "Careers",
  },
]

const iconRow = [
  {
    imgSrc: "../images/careers/icon1.png",
    title: "COMPENSATION",
    content: "Competitive salaries, bonus opportunities, generous 401k match",
  },
  {
    imgSrc: "../images/careers/icon2.png",
    title: "INSURANCE",
    content: "Vigilant pays 100% of medical premiums",
  },
  {
    imgSrc: "../images/careers/icon3.png",
    title: "WORK LIFE BALANCE",
    content: "Unlimited PTO knowing that your family comes first",
  },
  {
    imgSrc: "../images/careers/icon4.png",
    title: "TRAINING/EDUCATION ",
    content:
      "Learning tracks available to help our vigilantes continue to develop their careers",
  },
  {
    imgSrc: "../images/careers/icon5.png",
    title: "FUN OFFICE ENVIRONMENT",
    content: "Fully stocked fridge and snack bar, video games and beer Fridays",
  },
]

const CareersVig = () => (
  <Layout>
    <Seo title="CAREERS AT VIGILANT" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-4">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>CAREERS AT VIGILANT</h1>
            <h3 className="pt-5 b-color">BECOME A VIGILANTE</h3>
            
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <div>
    <section className="section-features">
        <div className="container">
          
          <div className="feat-box">
            <div className="row btn--bp">
            <a
                  href="https://vigilantnow.isolvedhire.com/jobs/893084-290557.html"
                  alt="Security Analyst"
                  target="__blank"
                  className="career--btn-lk"
                >
                  <div className="col">
                  <div className="row" style={{ textAlign: "left", paddingLeft: "1em" }}>
                    <div className="col-sm-12 col-md-3">
                  <h5 className="pt-3">Security Analyst</h5>
              </div>
              <div className="col-sm-12 col-md-3 offset-md-6">

                  <p>
                    Cincinnati, Ohio <br /> Full Time
                  </p>
              </div>
                    </div>
                  </div>
            </a>
            </div>
            <hr />
            <div className="row btn--bp">
            <a
                  href="https://vigilantnow.isolvedhire.com/jobs/922839-290557.html"
                  alt="Endpoint Security Specialist"
                  target="__blank"
                  className="career--btn-lk"
                >
                  <div className="col">
                  <div className="row" style={{ textAlign: "left", paddingLeft: "1em" }}>
                    <div className="col-sm-12 col-md-3">
                  <h5 className="pt-3">Endpoint Security Specialist</h5>
              </div>
              <div className="col-sm-12 col-md-3 offset-md-6">

                  <p>
                    Cincinnati, Ohio <br /> Full Time
                  </p>
              </div>
                    </div>
                  </div>
            </a>
            </div>
            <hr />
            <div className="row btn--bp">
            <a
                  href="https://vigilantnow.isolvedhire.com/jobs/922793-290557.html"
                  alt="Penetration Tester"
                  target="__blank"
                  className="career--btn-lk"
                >
                  <div className="col">
                  <div className="row" style={{ textAlign: "left", paddingLeft: "1em" }}>
                    <div className="col-sm-12 col-md-3">
                  <h5 className="pt-3">Penetration Tester</h5>
              </div>
              <div className="col-sm-12 col-md-3 offset-md-6">

                  <p>
                    Cincinnati, Ohio <br /> Full Time
                  </p>
              </div>
                    </div>
                  </div>
            </a>
            </div>
            <hr />
            
            <div style={{ justifyContent: "center" }}>
              <a
                href="mailto:careers@vigilantnow.com?subject=Application"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    GET IN TOUCH
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </a>
            </div>
          </div>

        </div>
      </section>
      <section className="section-overview">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h3 className="h-white">
              A MEANINGFUL PLACE TO DEVELOP, ADVANCE, AND THIRIVE 
              </h3>

              <hr />
            </div>
          </div>
          <div className="row">
            <div className="section-col-2">
              <p>
              The Vigilant Mission is to defend and protect with a vengeance. That means we protect people, businesses and society against those who exploit weakness. We welcome colleagues who will never quit living that mission every single day. Whether it is going above and beyond for our clients or taking a moment to support a fellow teammate, being a part of the Vigilant family includes experiencing our mission as part of your daily life and purpose.
              </p>
              <p>
              We seek out team members who have the drive and ambition it takes to change the world through their actions, while building a better future for all. 
              </p>
            </div>
            <div className="section-col-3">
              <p>
              We look for the best—threat hunters, developers, analysts, support staff, sales, service and leadership—and we will always stand side by side together, through the victories and challenges.
              </p>
              <p>
              We will support your growth and help develop your skills further – whether it is getting better at your current job through more certifications or promoting you up through the organization. We take responsibility to make you and the entire Vigilant team all we can be. That is our promise.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="grid--bg --for-it-ops-1">
        <section className="section-features">
          <div className="container">
            <div className="row"></div>
            <div className="feat-box">
              <ul className="section-5-icons--list">
                {iconRow.map(data => {
                  return (
                    <IconRow
                      iconImg={data.imgSrc}
                      iconTitle={data.title}
                      iconContent={data.content}
                      key={uuidv4()}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </section>

        <section className="section-box-cta">
          <div className="container">
            <div className="row">
              <div className="section-col-2">
                <div className="section-col-1--content">
                  <p className="p-quote">
                    “Our Teammates are our secret ingredient. We will fight for
                    their development and expertise in their roles. Teammates
                    are our family; and our family is our priority.”
                  </p>
                  <hr />
                  <p className="p-quote--author">
                    - ARIEL ACUNA, VIGILANT CHIEF PEOPLE OFFICER
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mx-auto text-center">
              <div
            className="pb-5  pt-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <StaticImage
              src="../images/careers/footImage.png"
              quality={95}
              formats={["png"]}
              layout="fixed"
              alt="8 Questions"
              className="img-fluid"
            />
          </div>
              </div>

            </div>
          </div>
        </section>
      </div>
      
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default CareersVig
